import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';

import { QB_ROOT_PATH } from '@src/constants/config';
import { Layout } from 'components';
// import { SearchFilters } from '@src/pages/question-bank';
import { formatQualification, removeHyphens } from '@src/utils/utils';
import { TabContent, TabNavItem } from '@src/components/UI/Tabs';
import { getDocumentPreviewUrl } from '@src/utils/documents/url';
import { useIsQbSubscribed } from '@src/hooks/user';
import { WorksheetCheckbox, WorkSheetMaker } from 'components/WorksheetMaker';

// import Navigate from '@src/components/Search/Navigate';
import ExternalLink from '../../assets/icons/external-link.svg';
import * as styles from './question-bank.module.css';
import QuestionBankGuard from '@src/guards';

const Tag = ({ format, children }) => {
  if (!children) return null;
  return <div className={styles.tag}>{children}</div>;
};

const path = (root, slug) => `${root}${slug}`;

const refineParams = (resourceType, qualification, params) => {
  return encodeURI(
    `/question-bank?questions[refinementList][resource_type][0]=${resourceType}&questions[refinementList][qualification][0]=${qualification}&questions[refinementList]${params}`,
  );
};

// const API_ROOT = 'https://www.mymathscloud.com/api/download/document?id=';

const Page = ({
  data: {
    datoCmsQuestion: {
      slug,
      resourceType,
      series,
      questionNumber,
      qualification,
      paper,
      difficulty,
      documentPaper,
      documentMarkScheme,
      documentWrittenMarkScheme,
      supportingDocument,
      imageQuestion,
      imageMarkScheme,
      imageWrittenMarkScheme,
      area,
      subtopic,
      keyword,
    },
  },
}) => {
  const tabs = [
    {
      id: 'question',
      title: 'Question',
      content: <img alt="Question" src={path(QB_ROOT_PATH, imageQuestion)} />,
    },
    {
      id: 'scheme',
      title: 'Mark Scheme',
      content: <img alt="Mark Scheme" src={path(QB_ROOT_PATH, imageMarkScheme)} />,
    },
    {
      id: 'written',
      title: 'Written Mark Scheme',
      content: <img alt="Mark Scheme" src={path(QB_ROOT_PATH, imageWrittenMarkScheme)} />,
    },
  ];

  const papers = [
    {
      id: 'paper',
      title: 'Full Paper',
      path: getDocumentPreviewUrl(documentPaper),
    },
    {
      id: 'mark',
      title: 'Full Mark Scheme',
      path: getDocumentPreviewUrl(documentMarkScheme),
    },
    {
      id: 'written',
      title: 'Full Written Mark Scheme',
      path: getDocumentPreviewUrl(documentWrittenMarkScheme),
    },
    ...supportingDocument?.map((doc) => ({
      id: doc.originalId,
      title: doc.name,
      linkText: doc.linkText,
      path: `/embeded?id=${doc.originalId}`,
    })),
  ];

  const [activeTab, setActiveTab] = useState('question');

  const isSubscribed = useIsQbSubscribed();

  return (
    <Layout>
      <QuestionBankGuard />
      {isSubscribed ? (
        <div className="container">
          <WorkSheetMaker />
          <div className={styles.information}>
            <div>
              <h1 className={styles.h1}>{formatQualification(qualification)}</h1>
              <WorksheetCheckbox
                hit={{
                  slug: slug,
                  title: `${removeHyphens(series)} - ${removeHyphens(paper)} - Q${questionNumber}`,
                }}
              />

              <div className={styles.flexWrapper}>
                <Tag>{resourceType}</Tag>
                <Tag>{removeHyphens(series)}</Tag>
                <Tag>{removeHyphens(paper)}</Tag>
                <Tag>{difficulty}</Tag>
              </div>

              {!!area.length && (
                <div className={styles.flexWrapper}>
                  <p>Area(s)</p>
                  {area?.map((a) => (
                    <Tag key={a.title}>
                      <Link to={refineParams(resourceType, qualification, `[area][0]=${a.title}`)}>
                        {a.title}
                      </Link>
                    </Tag>
                  ))}
                </div>
              )}

              {!!subtopic.length && (
                <div className={styles.flexWrapper}>
                  <p>Topic(s)</p>
                  {subtopic?.map((s) => (
                    <Tag key={s.title}>
                      <Link
                        to={refineParams(resourceType, qualification, `[subtopic][0]=${s.title}`)}
                      >
                        {s.title}
                      </Link>
                    </Tag>
                  ))}
                </div>
              )}

              {!!keyword.length && (
                <div className={styles.flexWrapper}>
                  <p>Keyword(s)</p>
                  {keyword.map((k) => (
                    <Tag key={k.title}>
                      <Link
                        to={refineParams(resourceType, qualification, `[keyword][0]=${k.title}`)}
                      >
                        {k.title}
                      </Link>
                    </Tag>
                  ))}
                </div>
              )}
            </div>

            <div className={styles.buttons}>
              {papers.map((paper) => {
                if (!paper.path || !paper.title) return null;
                return (
                  <a
                    key={paper.id}
                    href={paper.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button w-button"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={ExternalLink}
                      width={18}
                      alt="Document Icon"
                      style={{ marginRight: 5 }}
                    />
                    {paper.title}
                  </a>
                );
              })}
            </div>
          </div>

          <div className="Tabs" style={{ marginTop: 30, marginRight: 'auto' }}>
            <div className="nav">
              {tabs.map((tab) => (
                <TabNavItem
                  key={tab.id}
                  title={tab.title}
                  id={tab.id}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              ))}
            </div>
            <div className="outlet">
              {tabs.map((tab) => (
                <TabContent key={tab.id} id={tab.id} activeTab={activeTab}>
                  {tab.content}
                </TabContent>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="container">Please subscribe to view this content</div>
      )}
    </Layout>
  );
};

export default Page;

const query = graphql`
  query ($slug: String!) {
    datoCmsQuestion(slug: { eq: $slug }) {
      slug
      resourceType
      series
      questionNumber
      qualification
      difficulty
      paper
      documentPaper {
        id
        originalId
        price
        name
        displayName
        fileUrl
        externalUrl
        videoSolution
        module {
          originalId
          slug
        }
        documentCategory {
          originalId
          id
          name
          slug
        }
        freeDocument
        hasPassword
        isFeatured
        freeSample
        isSubscription
        seo {
          title
          description
          twitterCard
        }
        docCategory {
          name
          originalId
        }
        videoGuide {
          url
        }
        previewVideo
        documentType
        pastPaperType
        docType {
          id
          name
        }
        docPastPaperType {
          id
          name
        }
        docExamCourse {
          id
          name
        }
        docExamBoard {
          id
          name
        }
        docExamCourseCode {
          id
          name
        }
        docExamTier {
          id
          name
        }
        docExamPracticeType {
          id
          name
        }
        docExamTextbookType {
          id
          name
        }
        docExamModules {
          id
          name
        }
        docRelatedTopics {
          id
          name
        }
        docExamYear
      }
      documentMarkScheme {
        id
        originalId
        price
        name
        displayName
        fileUrl
        externalUrl
        videoSolution
        module {
          originalId
          slug
        }
        documentCategory {
          originalId
          id
          name
          slug
        }
        freeDocument
        hasPassword
        isFeatured
        freeSample
        isSubscription
        seo {
          title
          description
          twitterCard
        }
        docCategory {
          name
          originalId
        }
        videoGuide {
          url
        }
        previewVideo
        documentType
        pastPaperType
        docType {
          id
          name
        }
        docPastPaperType {
          id
          name
        }
        docExamCourse {
          id
          name
        }
        docExamBoard {
          id
          name
        }
        docExamCourseCode {
          id
          name
        }
        docExamTier {
          id
          name
        }
        docExamPracticeType {
          id
          name
        }
        docExamTextbookType {
          id
          name
        }
        docExamModules {
          id
          name
        }
        docRelatedTopics {
          id
          name
        }
        docExamYear
      }
      documentWrittenMarkScheme {
        id
        originalId
        price
        name
        displayName
        fileUrl
        externalUrl
        videoSolution
        module {
          originalId
          slug
        }
        documentCategory {
          originalId
          id
          name
          slug
        }
        freeDocument
        hasPassword
        isFeatured
        freeSample
        isSubscription
        seo {
          title
          description
          twitterCard
        }
        docCategory {
          name
          originalId
        }
        videoGuide {
          url
        }
        previewVideo
        documentType
        pastPaperType
        docType {
          id
          name
        }
        docPastPaperType {
          id
          name
        }
        docExamCourse {
          id
          name
        }
        docExamBoard {
          id
          name
        }
        docExamCourseCode {
          id
          name
        }
        docExamTier {
          id
          name
        }
        docExamPracticeType {
          id
          name
        }
        docExamTextbookType {
          id
          name
        }
        docExamModules {
          id
          name
        }
        docRelatedTopics {
          id
          name
        }
        docExamYear
      }
      supportingDocument {
        id
        originalId
        fileUrl
        linkText
        name
      }
      imageQuestion
      imageMarkScheme
      imageWrittenMarkScheme
      area {
        title
      }
      subtopic {
        title
      }
      keyword {
        title
      }
    }
  }
`;

export { query };
